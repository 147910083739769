export const reduceHelpCenterQuestions = (dataMapElement, searchQuery) => {
  let pageSections = dataMapElement.reduce((pageSections, question) => {
    let sectionName = question.Section

    let currentSection = pageSections.find(
      (pageSection) => pageSection.section === sectionName
    )

    let currentSectionFields = currentSection ? currentSection.fields : []

    currentSectionFields = [...currentSectionFields, question]
    currentSectionFields = currentSectionFields.sort(
      (firstFormField, secondFormField) =>
        firstFormField.order - secondFormField.order
    )

    let newPageSection = {
      section: question.section,
      fields: currentSectionFields,
      order: question.sectionOrder,
    }

    let tempPageSections = pageSections.filter(
      (pageSection) => pageSection.section !== sectionName
    )
    tempPageSections = [...tempPageSections, newPageSection]

    return tempPageSections
  }, [])

  let mappedPagedSections = pageSections.map((section) => {
    return {
      section: section?.section,
      fields: section?.fields?.filter(
        (field) =>
          field.Question?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          field.Section?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          field.Answer.childMarkdownRemark.html
            ?.toLowerCase()
            .includes(searchQuery?.toLowerCase())
      ),
    }
  })

  const results = mappedPagedSections.filter((element) => {
    if (element.fields.length !== 0) {
      return true
    }
    return false
  })

  return results
}
