import { useStaticQuery, graphql } from "gatsby"

const ALL_HELP_CENTER_QUESTIONS = graphql`
  query {
    allAirtableHelpCenter(sort: { order: ASC, fields: data___Order }) {
      nodes {
        data {
          Section
          Question
          Answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export const useHelpCenterQuestions = () => {
  const data = useStaticQuery(ALL_HELP_CENTER_QUESTIONS)
  return data
}
