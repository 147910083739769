import React, { useState, useEffect } from "react"
import Container from "../../Layout/Container"
import Collapsible from "elements/Collapsible"

import HelpCenterSearch from "./HelpCenterSearch"

import { useHelpCenterQuestions } from "../hooks/useHelpCenterQuestions"
import { reduceHelpCenterQuestions } from "../services/reduceHelpCenterData"

import { reduce } from "lodash"
import Message from "../../Elements/Message"
import Layout from "layout"

const HelpCenter = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const data = useHelpCenterQuestions()
  let airtablehelpCenterQuestions = data.allAirtableHelpCenter.nodes.map(
    (node) => node.data
  )

  let dataElementMap = airtablehelpCenterQuestions.map((formField) => ({
    ...formField,
    section: formField.Section,
  }))

  let sectionHelpCenterQuestions = reduceHelpCenterQuestions(
    dataElementMap,
    searchQuery
  )

  const handleSearchChange = async (event) => {
    setSearchQuery(event.target.value)
  }

  return (
    <Layout display={{ helpCenterBanner: false, footer: true }}>
      <Container isCentered>
        <h2>Frequently Asked Questions</h2>
        <hr />
        <HelpCenterSearch
          placeholder="Search"
          handleOnChange={handleSearchChange}
        />

        {sectionHelpCenterQuestions.length > 0 ? (
          sectionHelpCenterQuestions.map((section) => {
            return (
              <div>
                <h4>{section.section}</h4>
                {section?.fields.map((field) => (
                  <Collapsible
                    title={field.Question}
                    isOpen={searchQuery ? true : false}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: field.Answer.childMarkdownRemark.html,
                      }}
                    ></div>
                  </Collapsible>
                ))}
              </div>
            )
          })
        ) : (
          <Message>
            For inquiries and questions not found in the help center, please
            email us at{" "}
            <a href="mailto:sitel.med@medgrocer.com">sitel.med@medgrocer.com</a>
            .
          </Message>
        )}
      </Container>
    </Layout>
  )
}

export default HelpCenter
