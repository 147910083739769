import React, { Fragment, useState, useEffect } from "react"
import classNames from "classnames"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"

const HelpCenterSearch = ({
  type,
  name,
  placeholder,
  isMobile,
  handleOnChange,
}) => {
  return (
    <Fragment>
      <div className="field">
        <div className="field-body">
          <div className="field">
            <p className={classNames("control has-icons-left")}>
              <input
                type={type}
                className="input"
                name={name}
                placeholder={placeholder}
                onChange={handleOnChange}
                readOnly={isMobile}
              />
              <span className={classNames("icon is-small is-left")}>
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default HelpCenterSearch
